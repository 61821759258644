"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConfigValue = exports.initConfig = exports.getAppConfig = void 0;
var configCache = null;
var isConfigInited = false; // for panel

var initConfig = function initConfig(config) {
  isConfigInited = true;
  configCache = config;
};

exports.initConfig = initConfig;

var getAppConfig = function getAppConfig() {
  if (isConfigInited) {
    if (typeof configCache === 'function') {
      return configCache(); //test
    }

    return configCache;
  }

  if (configCache === null) {
    if (process.browser === true) {
      var appConfigElement = document.getElementById('app-config');
      configCache = JSON.parse(appConfigElement.dataset.config);
    } else {
      configCache = {
        SENTRY_PUBLIC_DSN: process.env.SENTRY_PUBLIC_DSN,
        SENTRY_RELEASE: process.env.SENTRY_RELEASE,
        RECAPTCHA_V2_KEY: process.env.RECAPTCHA_V2_KEY,
        RECAPTCHA_V3_KEY: process.env.RECAPTCHA_V3_KEY,
        isRecaptchaDisabled: process.env.CRYPTO_RECAPTCHA_CHECK_DISABLED === 'true',
        MIXPANEL_ID: process.env.MIXPANEL_ID,
        GA_TRACKING_ID: process.env.GA_TRACKING_ID,
        GTM_TRACKING_ID: process.env.GTM_TRACKING_ID,
        CRYPTO_WS_URL: process.env.CRYPTO_WS_URL,
        RPC_URL: process.env.RPC_URL,
        CRYPTO_ECP_API_HOST: process.env.CRYPTO_ECP_API_HOST
      };
    }
  }

  return configCache;
};

exports.getAppConfig = getAppConfig;

var getConfigValue = function getConfigValue(property) {
  if (isConfigInited) {
    if (typeof configCache === 'function') {
      return configCache(property); //test
    }

    return configCache[property];
  }

  return getAppConfig()[property];
};

exports.getConfigValue = getConfigValue;