"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Svg;

var _react = _interopRequireDefault(require("react"));

var __jsx = _react["default"].createElement;

function Svg(_ref) {
  var src = _ref.src,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? null : _ref$className;

  if (typeof src === 'function') {
    var Component = src;
    return __jsx(Component, {
      className: className
    });
  }

  return __jsx("svg", {
    viewBox: src.viewBox,
    width: src.width,
    height: src.height,
    className: className
  }, __jsx("use", {
    xlinkHref: "#".concat(src.id)
  }));
}