"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _logout = _interopRequireDefault(require("../machines/logout"));

var _panel = require("../actions/panel");

var _reducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var defaultState = {
  userModal: null,
  userData: null,
  userDataStatus: null,
  resolvedCountry: ""
};
var _default = {
  machines: [_logout["default"]],
  reducer: (_reducer = {}, (0, _defineProperty2["default"])(_reducer, _panel.showLogin, function (state) {
    return _objectSpread({}, state, {
      userModal: 'login'
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.showRestore, function (state) {
    return _objectSpread({}, state, {
      userModal: 'restore'
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.showRegister, function (state) {
    return _objectSpread({}, state, {
      userModal: 'register'
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.closeModal, function (state) {
    return _objectSpread({}, state, {
      userModal: null
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.requestUserData, function (state) {
    return _objectSpread({}, state, {
      userDataStatus: 'inProgress'
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.receiveUserData, function (state, userData) {
    return _objectSpread({}, state, {
      userData: userData,
      userDataStatus: 'success'
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.errorUserData, function (state, resolvedCountry) {
    return _objectSpread({}, state, {
      userDataStatus: 'error',
      resolvedCountry: resolvedCountry
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.setUserTwoFaDisabled, function (state) {
    return _objectSpread({}, state, {
      userData: _objectSpread({}, state.userData, {
        tfaEnabled: false
      })
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.setUserTwoFaEnabled, function (state) {
    return _objectSpread({}, state, {
      userData: _objectSpread({}, state.userData, {
        tfaEnabled: true
      })
    });
  }), (0, _defineProperty2["default"])(_reducer, _panel.updateAvatar, function (state, avatar) {
    return _objectSpread({}, state, {
      userData: _objectSpread({}, state.userData, {
        avatar: avatar
      })
    });
  }), _reducer),
  defaultState: defaultState
};
exports["default"] = _default;