"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeAppConfig = exports.updateAvatar = exports.setUserTwoFaDisabled = exports.setUserTwoFaEnabled = exports.errorUserData = exports.receiveUserData = exports.requestUserData = exports.getUserData = exports.closeModal = exports.showRestore = exports.showRegister = exports.showLogin = void 0;

var _reduxAct = require("redux-act");

var showLogin = (0, _reduxAct.createAction)('panel.showLogin');
exports.showLogin = showLogin;
var showRegister = (0, _reduxAct.createAction)('panel.showRegister');
exports.showRegister = showRegister;
var showRestore = (0, _reduxAct.createAction)('panel.showRestore');
exports.showRestore = showRestore;
var closeModal = (0, _reduxAct.createAction)('panel.closeModal');
exports.closeModal = closeModal;
var getUserData = (0, _reduxAct.createAction)('panel.getUserData');
exports.getUserData = getUserData;
var requestUserData = (0, _reduxAct.createAction)('panel.requestUserData');
exports.requestUserData = requestUserData;
var receiveUserData = (0, _reduxAct.createAction)('panel.receiveUserData');
exports.receiveUserData = receiveUserData;
var errorUserData = (0, _reduxAct.createAction)('panel.errorUserData');
exports.errorUserData = errorUserData;
var setUserTwoFaEnabled = (0, _reduxAct.createAction)('panel.setUserTwoFaEnabled');
exports.setUserTwoFaEnabled = setUserTwoFaEnabled;
var setUserTwoFaDisabled = (0, _reduxAct.createAction)('panel.setUserTwoFaDisabled');
exports.setUserTwoFaDisabled = setUserTwoFaDisabled;
var updateAvatar = (0, _reduxAct.createAction)('panel.updateAvatar');
exports.updateAvatar = updateAvatar;
var storeAppConfig = (0, _reduxAct.createAction)('panel.storeAppConfig');
exports.storeAppConfig = storeAppConfig;