"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

var _redux = require("redux");

var _reduxSaga = _interopRequireDefault(require("redux-saga"));

var _reducers = _interopRequireDefault(require("../redux/panel/reducers"));

var _sagas = _interopRequireDefault(require("../redux/panel/sagas"));

var reduxStore;
var sagaTask;
var sagaMiddleware;

function _default() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var isProduction = process.env.NODE_ENV === 'production';
  sagaMiddleware = (0, _reduxSaga["default"])();
  var middlewares = [(0, _redux.applyMiddleware)(sagaMiddleware)];

  if (!isProduction && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  reduxStore = (0, _redux.createStore)((0, _redux.combineReducers)(_reducers["default"]), initialState, _redux.compose.apply(null, middlewares));

  if (sagaTask) {
    sagaTask.cancel();
  }

  sagaTask = sagaMiddleware.run(_sagas["default"]);
  return reduxStore;
}