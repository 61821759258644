"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Svg = _interopRequireDefault(require("../../Svg"));

var __jsx = _react["default"].createElement;

var EmailSvg = function EmailSvg(props) {
  return __jsx("svg", props, __jsx("defs", null, __jsx("linearGradient", {
    id: "a",
    x1: "54.41%",
    x2: "74.997%",
    y1: "63.965%",
    y2: "15.161%"
  }, __jsx("stop", {
    offset: "0%"
  }), __jsx("stop", {
    offset: "100%",
    stopOpacity: "0"
  })), __jsx("linearGradient", {
    id: "b",
    x1: "36.775%",
    x2: "59.833%",
    y1: "87.674%",
    y2: "23.848%"
  }, __jsx("stop", {
    offset: "0%"
  }), __jsx("stop", {
    offset: "100%",
    stopOpacity: "0"
  })), __jsx("linearGradient", {
    id: "d",
    x1: "50%",
    x2: "50%",
    y1: "84.407%",
    y2: "32.288%"
  }, __jsx("stop", {
    offset: "0%",
    stopOpacity: ".1"
  }), __jsx("stop", {
    offset: "100%",
    stopOpacity: "0"
  })), __jsx("rect", {
    id: "c",
    width: "100",
    height: "60",
    x: "10",
    y: "20",
    rx: "2"
  }), __jsx("path", {
    id: "e",
    d: "M0 40l60 30 60-30v54a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V40z"
  }), __jsx("path", {
    id: "f",
    d: "M110 60L60 80 10 60"
  }), __jsx("circle", {
    id: "g",
    cx: "4",
    cy: "39",
    r: "4"
  }), __jsx("circle", {
    id: "h",
    cx: "3",
    cy: "27",
    r: "3"
  }), __jsx("circle", {
    id: "i",
    cx: "2",
    cy: "17",
    r: "2"
  }), __jsx("circle", {
    id: "j",
    cx: "2",
    cy: "17",
    r: "2"
  })), __jsx("g", {
    fill: "none",
    fillRule: "evenodd"
  }, __jsx("path", {
    fill: "url(#a)",
    d: "M130 10l-20 155 95 15 35-140z",
    opacity: ".03"
  }), __jsx("path", {
    fill: "url(#b)",
    d: "M106 4.5L93.5 130l63.5-23.5L177 30z",
    opacity: ".03"
  }), __jsx("path", {
    fill: "#005CB8",
    d: "M96.82 140.623l106.36 18.754a6 6 0 0 0 6.95-4.867l3.417-19.376a6 6 0 0 0-3.052-6.319l-51.813-28.055-58.283 8.643a6 6 0 0 0-5.029 4.893l-3.417 19.376a6 6 0 0 0 4.867 6.951z"
  }), __jsx("g", {
    transform: "rotate(10 -466.503 614.352)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#c"
  }), __jsx("use", {
    fill: "url(#d)",
    xlinkHref: "#c"
  })), __jsx("path", {
    stroke: "#005CB8",
    strokeLinecap: "square",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M148.261 142.585l1.622 2.317 5.793-4.055"
  }), __jsx("g", {
    transform: "rotate(10 -466.503 614.352)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#e"
  }), __jsx("path", {
    stroke: "#000",
    strokeOpacity: ".2",
    strokeWidth: "2",
    d: "M1 41.618V94a5 5 0 0 0 5 5h108a5 5 0 0 0 5-5V41.618l-59 29.5-59-29.5z"
  })), __jsx("g", {
    transform: "scale(1 -1) rotate(-10 -1266.606 -544.352)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#f"
  }), __jsx("path", {
    stroke: "#000",
    strokeOpacity: ".2",
    strokeWidth: "2",
    d: "M104.807 61H15.193L60 78.923 104.807 61z"
  })), __jsx("path", {
    stroke: "#000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeOpacity: ".2",
    strokeWidth: "2",
    d: "M53.32 39.187l-4.863 27.575"
  }), __jsx("g", {
    transform: "rotate(10 -192.438 301.704)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#g"
  }), __jsx("circle", {
    cx: "4",
    cy: "39",
    r: "3",
    stroke: "#000",
    strokeOpacity: ".2",
    strokeWidth: "2"
  })), __jsx("path", {
    stroke: "#000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeOpacity: ".2",
    strokeWidth: "2",
    d: "M218.077 20.013l-3.126 17.727"
  }), __jsx("g", {
    transform: "rotate(10 -.98 1239.428)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#h"
  }), __jsx("circle", {
    cx: "3",
    cy: "27",
    r: "2",
    stroke: "#000",
    strokeOpacity: ".2",
    strokeWidth: "2"
  })), __jsx("g", null, __jsx("path", {
    stroke: "#000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeOpacity: ".2",
    strokeWidth: "2",
    d: "M278.182 101.84l-1.736 9.848"
  }), __jsx("g", {
    transform: "rotate(10 -439.068 1629.558)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#i"
  }), __jsx("circle", {
    cx: "2",
    cy: "17",
    r: "1",
    stroke: "#000",
    strokeOpacity: ".2",
    strokeWidth: "2"
  }))), __jsx("g", null, __jsx("path", {
    stroke: "#000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeOpacity: ".2",
    strokeWidth: "2",
    d: "M128.182 61.84l-1.736 9.848"
  }), __jsx("g", {
    transform: "rotate(10 -285.467 752.304)"
  }, __jsx("use", {
    fill: "#FFF",
    xlinkHref: "#j"
  }), __jsx("circle", {
    cx: "2",
    cy: "17",
    r: "1",
    stroke: "#000",
    strokeOpacity: ".2",
    strokeWidth: "2"
  })))));
};

EmailSvg.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  width: "300",
  height: "300",
  viewBox: "0 0 300 300"
};

var RegisterSuccess = function RegisterSuccess(_ref) {
  var onClose = _ref.onClose;
  return __jsx("div", {
    className: "success-text"
  }, __jsx("h3", null, "Activation link sent"), __jsx(_Svg["default"], {
    src: EmailSvg,
    className: "email-image"
  }), __jsx("h5", null, "Please check your email"), __jsx("div", {
    className: "auth-form-delimeter"
  }), __jsx("div", {
    className: "submit-button-row"
  }, __jsx("button", {
    className: "button submit-button",
    onClick: onClose
  }, "Close")));
};

RegisterSuccess.propTypes = {
  onClose: _propTypes["default"].func.isRequired
};
var _default = RegisterSuccess;
exports["default"] = _default;