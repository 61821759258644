"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VALIDATION_RULES = void 0;
var VALIDATION_RULES = [{
  schema: function schema(value) {
    return value.toLocaleUpperCase() !== value;
  },
  text: 'One lowercase character'
}, {
  schema: function schema(value) {
    return value.toLocaleLowerCase() !== value;
  },
  text: 'One uppercase character'
}, {
  schema: function schema(value) {
    return value.length >= 8;
  },
  text: 'Minimum 8 characters'
}, {
  schema: function schema(value) {
    return /[0-9]/.test(value);
  },
  text: 'One number'
}];
exports.VALIDATION_RULES = VALIDATION_RULES;