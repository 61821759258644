"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFieldErrorFromValidationResults = void 0;

var _get2 = _interopRequireDefault(require("lodash/get"));

var getFieldErrorFromValidationResults = function getFieldErrorFromValidationResults(field, validationResults) {
  var error = (0, _get2["default"])(validationResults, field + '.message', null);

  if (!error) {
    return null;
  }

  return error;
};

exports.getFieldErrorFromValidationResults = getFieldErrorFromValidationResults;