"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearFields = exports.setDefaultValues = exports.clearErrors = exports.setFieldsErrors = exports.setErrors = exports.inputField = exports.unregisterForm = exports.registerForm = exports.unregisterField = exports.registerField = void 0;

var _reduxAct = require("redux-act");

var registerField = (0, _reduxAct.createAction)('forms.registerField');
exports.registerField = registerField;
var unregisterField = (0, _reduxAct.createAction)('forms.unregisterField');
exports.unregisterField = unregisterField;
var registerForm = (0, _reduxAct.createAction)('forms.registerForm');
exports.registerForm = registerForm;
var unregisterForm = (0, _reduxAct.createAction)('forms.unregisterForm');
exports.unregisterForm = unregisterForm;
var inputField = (0, _reduxAct.createAction)('forms.inputField');
exports.inputField = inputField;
var setErrors = (0, _reduxAct.createAction)('forms.setErrors');
exports.setErrors = setErrors;
var setFieldsErrors = (0, _reduxAct.createAction)('forms.setFieldsErrors');
exports.setFieldsErrors = setFieldsErrors;
var clearErrors = (0, _reduxAct.createAction)('forms.clearErrors');
exports.clearErrors = clearErrors;
var setDefaultValues = (0, _reduxAct.createAction)('forms.setDefaultValues');
exports.setDefaultValues = setDefaultValues;
var clearFields = (0, _reduxAct.createAction)('forms.clearFields');
exports.clearFields = clearFields;