"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _panel = _interopRequireDefault(require("./panel"));

var _forms = _interopRequireDefault(require("../../reducers/forms"));

var _prepareRootReducer = _interopRequireDefault(require("../../../services/prepareRootReducer"));

var _default = (0, _prepareRootReducer["default"])({
  panel: _panel["default"],
  forms: _forms["default"]
});

exports["default"] = _default;