"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SUSPENDED_MODAL = exports.TERMS_OF_USE_MODAL = exports.GDPR_MODAL = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var GDPR_MODAL = 'GDPR_MODAL';
exports.GDPR_MODAL = GDPR_MODAL;
var TERMS_OF_USE_MODAL = 'TERMS_OF_USE_MODAL';
exports.TERMS_OF_USE_MODAL = TERMS_OF_USE_MODAL;
var SUSPENDED_MODAL = 'SUSPENDED_MODAL';
exports.SUSPENDED_MODAL = SUSPENDED_MODAL;
var COOKIE_FOR_SKIP_NAME = 'skip-show-modal';

function isNeededShowFromCookie(modalType) {
  return _jsCookie["default"].get(COOKIE_FOR_SKIP_NAME) !== modalType;
}

function resetCookie() {
  _jsCookie["default"].remove(COOKIE_FOR_SKIP_NAME);
}

function getIsNeededGDPR(gdprActivatedDatetime) {
  return gdprActivatedDatetime === null;
}

function getIsNeededSuspendedModal(isSuspended) {
  return isSuspended === true;
}

function getIsNeededTermsOfUseModal(termsAcceptedDatetime) {
  return termsAcceptedDatetime === null;
}

function getNeededModal(userData) {
  var gdprActivatedDatetime = userData.gdprActivatedDatetime,
      termsAcceptedDatetime = userData.termsAcceptedDatetime,
      isSuspended = userData.isSuspended;

  if (getIsNeededTermsOfUseModal(termsAcceptedDatetime)) {
    return TERMS_OF_USE_MODAL;
  }

  if (getIsNeededGDPR(gdprActivatedDatetime)) {
    return GDPR_MODAL;
  }

  if (getIsNeededSuspendedModal(isSuspended)) {
    return SUSPENDED_MODAL;
  }

  return null;
} // expires - in days or part of day(1/x day)


function setupCookie(modalType, expires) {
  _jsCookie["default"].set(COOKIE_FOR_SKIP_NAME, modalType, {
    expires: expires
  });
}

function extendTimeForModal(modalType) {
  switch (modalType) {
    case TERMS_OF_USE_MODAL:
      break;

    case GDPR_MODAL:
      break;
    // not need skip

    case SUSPENDED_MODAL:
      break;
    // show always

    default:
      break;
  }
}

var _default = {
  getNeededModal: getNeededModal,
  extendTimeForModal: extendTimeForModal,
  isNeededShowFromCookie: isNeededShowFromCookie,
  resetCookie: resetCookie
};
exports["default"] = _default;