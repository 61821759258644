"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _StateMachine = _interopRequireDefault(require("../../../services/StateMachine"));

var _default = new _StateMachine["default"]('logout');

exports["default"] = _default;