"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _forms = require("../actions/forms");

var _reducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var defaultState = {};

var inputFiledFn = function inputFiledFn(state, _ref) {
  var form = _ref.form,
      field = _ref.field,
      value = _ref.value;

  var newState = _objectSpread({}, state, (0, _defineProperty2["default"])({}, form, _objectSpread({}, state[form], {
    values: _objectSpread({}, state[form] ? state[form].values : {}, (0, _defineProperty2["default"])({}, field, value))
  })));

  if (newState[form] && newState[form].fieldsErrors) {
    delete newState[form].fieldsErrors[field];
    newState[form].fieldsErrors = _objectSpread({}, newState[form].fieldsErrors);
  }

  return newState;
};

var _default = {
  reducer: (_reducer = {}, (0, _defineProperty2["default"])(_reducer, _forms.unregisterForm, function (state, formName) {
    delete state[formName];
    return _objectSpread({}, state);
  }), (0, _defineProperty2["default"])(_reducer, _forms.inputField, inputFiledFn), (0, _defineProperty2["default"])(_reducer, _forms.setDefaultValues, function (state, _ref2) {
    var form = _ref2.form,
        values = _ref2.values;
    return Object.keys(values).reduce(function (acc, field) {
      return inputFiledFn(acc, {
        form: form,
        field: field,
        value: values[field]
      });
    }, state);
  }), (0, _defineProperty2["default"])(_reducer, _forms.setErrors, function (state, _ref3) {
    var form = _ref3.form,
        serverErrors = _ref3.serverErrors,
        fieldsErrors = _ref3.fieldsErrors;
    return _objectSpread({}, state, (0, _defineProperty2["default"])({}, form, _objectSpread({}, state[form], {
      serverErrors: serverErrors,
      fieldsErrors: fieldsErrors
    })));
  }), (0, _defineProperty2["default"])(_reducer, _forms.clearErrors, function (state, _ref4) {
    var form = _ref4.form;
    if (!state[form]) return state;
    delete state[form].serverErrors;
    delete state[form].fieldsErrors;
    return _objectSpread({}, state, (0, _defineProperty2["default"])({}, form, _objectSpread({}, state[form])));
  }), (0, _defineProperty2["default"])(_reducer, _forms.setFieldsErrors, function (state, _ref5) {
    var form = _ref5.form,
        fieldsErrors = _ref5.fieldsErrors;
    return _objectSpread({}, state, (0, _defineProperty2["default"])({}, form, _objectSpread({}, state[form], {
      fieldsErrors: fieldsErrors
    })));
  }), (0, _defineProperty2["default"])(_reducer, _forms.clearFields, function (state, _ref6) {
    var form = _ref6.form;
    return _objectSpread({}, state, (0, _defineProperty2["default"])({}, form, _objectSpread({}, state[form], {
      values: {}
    })));
  }), _reducer),
  defaultState: defaultState
};
exports["default"] = _default;