"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getElementWidth = exports.getAbsoluteHeight = exports.getHeight = exports.getCoords = exports.getWindowScrollTop = void 0;

var getWindowScrollTop = function getWindowScrollTop() {
  return window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
};

exports.getWindowScrollTop = getWindowScrollTop;

var getElemetScrollTop = function getElemetScrollTop(elem) {
  return elem.scrollTop;
};

var getCoords = function getCoords(elem) {
  var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;
  // crossbrowser version
  var box = elem.getBoundingClientRect();
  var body = document.body;
  var docEl = document.documentElement;
  var scrollTop = parent === window ? getWindowScrollTop() : getElemetScrollTop(parent);
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;
  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;
  return {
    top: Math.round(top),
    left: Math.round(left)
  };
};

exports.getCoords = getCoords;

var getHeight = function getHeight(elem) {
  return elem.offsetHeight;
};

exports.getHeight = getHeight;

var getAbsoluteHeight = function getAbsoluteHeight(el) {
  var styles = window.getComputedStyle(el);
  var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
  return Math.ceil(el.offsetHeight + margin);
};

exports.getAbsoluteHeight = getAbsoluteHeight;

var getElementWidth = function getElementWidth(el) {
  return el.getBoundingClientRect().width;
};

exports.getElementWidth = getElementWidth;