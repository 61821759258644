module.exports = [{
      plugin: require('/app/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"!!!GOOGLE_TAG_MANAGER_ID!!!","includeInDevelopment":false},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"!!!GOOGLE_ANALYTICS_ID!!!","includeInDevelopment":false},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"!!!SENTRY_PUBLIC_DSN!!!","environment":"production","enabled":true},
    },{
      plugin: require('/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
