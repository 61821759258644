"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DENIED_COUNTRIES = exports.WARNING_COUNTRIES = exports.ACCREDITED_INVESTOR_WARNING_COUNTRY_NAMES = exports.WARNING_COUNTRY_NAME_BY_ISO = exports.DENIED_COUNTRY_NAME_BY_ISO = void 0;
// import countryData from 'country-data';
// const DENIED_COUNTRIES_NAMES = [
//   'Cayman Islands',
//   'Bosnia & Herzegovina',
//   'Korea, Democratic People\'s Republic Of',
//   'Ethiopia',
//   'Iran, Islamic Republic Of',
//   'Iraq',
//   'Syrian Arab Republic',
//   'Uganda',
//   'Yemen',
//   'Tunisia',
//   'Trinidad And Tobago',
//   'Sri Lanka',
// ];
// const WARNING_COUNTRIES_NAMES = [
//   'United States',
//   'Canada',
//   'Australia',
//   'South Africa',
//   'New Zealand',
//   'Japan',
//   'China',
//   'Singapore',
//   'Korea, Republic Of',
//   'Sri Lanka',
// ];
var DENIED_COUNTRY_NAME_BY_ISO = {
  "KY": "Cayman Islands",
  "BA": "Bosnia & Herzegovina",
  "KP": "Korea, Democratic People's Republic Of",
  "ET": "Ethiopia",
  "IR": "Iran, Islamic Republic Of",
  "IQ": "Iraq",
  "SY": "Syrian Arab Republic",
  "UG": "Uganda",
  "YE": "Yemen",
  "TN": "Tunisia",
  "TT": "Trinidad And Tobago",
  "LK": "Sri Lanka",
  "US": "United States of America",
  "PR": "Puerto Rico",
  'CA': "Canada",
  'AU': "Austria",
  'ZA': "South Africa",
  'NZ': "New Zealand",
  'JP': "Japan",
  'SG': "Singapore",
  "HK": "Hong Kong"
};
exports.DENIED_COUNTRY_NAME_BY_ISO = DENIED_COUNTRY_NAME_BY_ISO;
var WARNING_COUNTRY_NAME_BY_ISO = {
  "CA": "Canada",
  "AU": "Australia",
  "ZA": "South Africa",
  "NZ": "New Zealand",
  "JP": "Japan",
  "SG": "Singapore",
  "LK": "Sri Lanka"
};
exports.WARNING_COUNTRY_NAME_BY_ISO = WARNING_COUNTRY_NAME_BY_ISO;
var ACCREDITED_INVESTOR_WARNING_COUNTRY_NAMES = {
  "US": {
    "long": "United States",
    "short": "U.S."
  }
};
exports.ACCREDITED_INVESTOR_WARNING_COUNTRY_NAMES = ACCREDITED_INVESTOR_WARNING_COUNTRY_NAMES;
var WARNING_COUNTRIES = Object.keys(WARNING_COUNTRY_NAME_BY_ISO); // WARNING_COUNTRIES_NAMES.map(country => {
//   const iso = countryData.lookup.countries({ name: country })[0].alpha2;
//   WARNING_COUNTRY_NAME_BY_ISO[iso] = country;
//   return iso;
// });

exports.WARNING_COUNTRIES = WARNING_COUNTRIES;
var DENIED_COUNTRIES = Object.keys(DENIED_COUNTRY_NAME_BY_ISO); // DENIED_COUNTRIES_NAMES.map(country => {
//   const iso = countryData.lookup.countries({ name: country })[0].alpha2;
//   DENIED_COUNTRY_NAME_BY_ISO[iso] = country;
//   return iso;
// });
// console.log('DENIED_COUNTRY_NAME_BY_ISO');
// console.log(JSON.stringify(DENIED_COUNTRY_NAME_BY_ISO));
// console.log('WARNING_COUNTRY_NAME_BY_ISO');
// console.log(JSON.stringify(WARNING_COUNTRY_NAME_BY_ISO));
// console.log('WARNING_COUNTRIES');
// console.log(JSON.stringify(WARNING_COUNTRIES));
// console.log('DENIED_COUNTRIES');
// console.log(JSON.stringify(DENIED_COUNTRIES));

exports.DENIED_COUNTRIES = DENIED_COUNTRIES;