"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = prepareRootReducer;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _reduxAct = require("redux-act");

var _StateMachine = require("./StateMachine");

function appendReducer(rootReducer, key, reducerObject) {
  if (typeof reducerObject !== 'object' || !reducerObject.reducer || !reducerObject.defaultState) {
    throw new Error("Reducer '".concat(key, "' must be plain object with keys {reducer, defaultState,[machines]}"));
  }

  if (Object.keys(reducerObject).length === 3) {
    if (!reducerObject.machines) {
      throw new Error("Reducer '".concat(key, "' has unknown property. Expects {reducer, defaultState,[machines]}"));
    }

    rootReducer[key] = _reduxAct.createReducer.apply(void 0, (0, _toConsumableArray2["default"])(_StateMachine.applyMachinesToReducer.apply(void 0, [key].concat((0, _toConsumableArray2["default"])(reducerObject.machines)))(reducerObject.reducer, reducerObject.defaultState)));
    return;
  }

  rootReducer[key] = (0, _reduxAct.createReducer)(reducerObject.reducer, reducerObject.defaultState);
}

function prepareRootReducer(reducers) {
  var rootReducer = {}; // custom reducer

  Object.keys(reducers).map(function (key) {
    return appendReducer(rootReducer, key, reducers[key]);
  });
  return rootReducer;
}