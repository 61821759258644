"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Register = void 0;

var _store = _interopRequireDefault(require("./store"));

var _AuthModal = _interopRequireDefault(require("../components/panel/AuthModal"));

var _RegisterForm = _interopRequireDefault(require("../components/screens/auth/RegisterForm"));

var _appConfig = require("../services/appConfig");

var Register = _RegisterForm["default"];
exports.Register = Register;
var _default = {
  AuthModal: _AuthModal["default"],
  createStore: _store["default"],
  initConfig: _appConfig.initConfig,
  Register: Register
};
exports["default"] = _default;