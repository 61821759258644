"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _uniqueId2 = _interopRequireDefault(require("lodash/uniqueId"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var __jsx = _react["default"].createElement;

var Checkbox = function Checkbox(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      label = _ref.label,
      isDisabled = _ref.isDisabled,
      children = _ref.children,
      name = _ref.name;
  var uniqueId = "checkbox-".concat((0, _uniqueId2["default"])());

  var handleChange = function handleChange(e) {
    if (isDisabled) {
      return;
    }

    onChange && onChange(value === 'true' ? 'false' : 'true', name);
  };

  return __jsx("label", {
    htmlFor: uniqueId,
    className: "custom-checkbox",
    "data-input-name": name
  }, __jsx("input", {
    id: uniqueId,
    type: "checkbox",
    checked: value === 'true' || false,
    onChange: handleChange
  }), children, __jsx("span", {
    className: "checkmark"
  }));
};

Checkbox.propTypes = {
  value: _propTypes["default"].string,
  onChange: _propTypes["default"].func,
  label: _propTypes["default"].any
};
var _default = Checkbox;
exports["default"] = _default;