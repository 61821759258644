"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _react = require("react");

var _reactGoogleRecaptchaV = require("react-google-recaptcha-v3");

var RecaptchaV3Consumer = function RecaptchaV3Consumer(_ref) {
  var children = _ref.children,
      onChange = _ref.onChange,
      name = _ref.name,
      value = _ref.value;

  var _useGoogleReCaptcha = (0, _reactGoogleRecaptchaV.useGoogleReCaptcha)(),
      executeRecaptcha = _useGoogleReCaptcha.executeRecaptcha;

  var _useState = (0, _react.useState)(false),
      inProgress = _useState[0],
      setInProgress = _useState[1];

  (0, _react.useEffect)(function () {
    var getToken = function getToken() {
      var token;
      return _regenerator["default"].async(function getToken$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return _regenerator["default"].awrap(executeRecaptcha());

            case 2:
              token = _context.sent;
              onChange(token, name);

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, null, null, null, Promise);
    };

    if (!value && !inProgress) {
      setInProgress(true);
      getToken().then(function () {
        setInProgress(false);
      })["catch"](function () {
        setInProgress(false);
      });
    }
  });
  return null;
};

var _default = RecaptchaV3Consumer;
exports["default"] = _default;