"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackGA = exports.trackEvent = exports.trackGtag = exports.pageview = exports.getGaCode = exports.getGtmId = exports.getMixpanelId = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _appConfig = require("./appConfig");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getGaId = function getGaId() {
  return (0, _appConfig.getAppConfig)().GA_TRACKING_ID;
};

var getMixpanelId = function getMixpanelId() {
  return (0, _appConfig.getAppConfig)().MIXPANEL_ID;
};

exports.getMixpanelId = getMixpanelId;

var getGtmId = function getGtmId() {
  return (0, _appConfig.getAppConfig)().GTM_TRACKING_ID;
};

exports.getGtmId = getGtmId;

var getGaCode = function getGaCode() {
  return {
    __html: "\nwindow.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;\nga('create', '".concat(getGaId(), "', 'auto');\nga('send', 'pageview')")
  };
}; // https://developers.google.com/analytics/devguides/collection/gtagjs/pages


exports.getGaCode = getGaCode;

var pageview = function pageview(url) {
  if (window.ga) {
    window.ga('send', 'pageview', url);
  }

  if (window.mixpanel) {// window.mixpanel.track(
    //   'Page view',
    //   {
    //     "location": window.location.href,
    //     "page_path": window.location.pathname,
    //   }
    // );
  }
};

exports.pageview = pageview;

var trackGtag = function trackGtag(action) {
  if (window.gtmTrack) {
    window.gtmTrack({
      event: action
    });
  }
}; // https://developers.google.com/analytics/devguides/collection/gtagjs/events


exports.trackGtag = trackGtag;

var trackEvent = function trackEvent(_ref) {
  var action = _ref.action,
      param = _ref.param,
      _ref$additional = _ref.additional,
      additional = _ref$additional === void 0 ? {} : _ref$additional;

  if (window.ga) {
    window.ga('send', 'event', action, param);
  }

  var params = _objectSpread({}, additional);

  if (param) {
    params.param = param;
  }

  if (window.mixpanel) {
    window.mixpanel.track(action, params);
  } else {
    console.log('track', action, params);
  }
};

exports.trackEvent = trackEvent;

var trackGA = function trackGA(_ref2) {
  var eventCategory = _ref2.eventCategory,
      eventAction = _ref2.eventAction,
      eventLabel = _ref2.eventLabel;

  if (window.ga) {
    window.ga('send', 'event', eventCategory, eventAction, eventLabel);
  }
};

exports.trackGA = trackGA;