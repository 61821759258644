"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resizeIframe = resizeIframe;
exports.lookupForDataAttribute = lookupForDataAttribute;
exports.findAncestor = void 0;

var findAncestor = function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls)) {
    ;
  }

  return el;
};

exports.findAncestor = findAncestor;

function resizeIframe(obj) {
  obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
}

function lookupForDataAttribute(element, dataAttributes, stopClass) {
  if (!element || !element.dataset) {
    return null;
  }

  if (element.classList.contains(stopClass)) {
    return null;
  }

  var emptyNeededData = dataAttributes.find(function (data) {
    return !element.dataset[data];
  });

  if (emptyNeededData) {
    return lookupForDataAttribute(element.parentNode, dataAttributes, stopClass);
  }

  var result = {};
  dataAttributes.forEach(function (data) {
    return result[data] = element.dataset[data];
  });
  return result;
}